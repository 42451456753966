import React, { useContext } from 'react';
import { ProjectsContext, ReducerContext } from 'app';
import { ButtonGlow } from 'components/utils/buttons/buttons';
import List from 'components/utils/list/list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faPlus, faSquare, faRepeat, faBars, faClock } from '@fortawesome/free-solid-svg-icons';
import { dateFormat, DateDiff, daysoverdue, getHoursMinsStr, isMyProjectTask } from 'components/utils/utils';

function MenuProject({ itemData }) {

  const { state, dispatch } = useContext(ReducerContext);

  const setUserProject = async () => {

    if (itemData._id !== state.activeProject) {
      dispatch({ type: "setActiveProjectID", payload: itemData._id })
    }
  }

  let tasksclass = parseInt(itemData?.incompletetaskcount) == 0 ? "alpha" : "";
  let approvedclass = parseInt(itemData?.unconfirmedtaskcount) == 0 ? "alpha" : "";
  let invoiceclass = parseInt(itemData?.readyforinvoicetaskcount) == 0 ? "alpha" : "";

  let nextDueDate = itemData?.next_repeat_date ? (dateFormat(itemData.next_repeat_date, "nice")) : dateFormat(itemData?.nextdue_date, "nice");

  let overdue = daysoverdue(nextDueDate, false);

  if (overdue > 0) {
    nextDueDate = <span style={{ color: "red" }}> {dateFormat(nextDueDate, "nice")} </span >;
  }
  return (
    <div className={`project ${itemData._id == state.activeProjectID ? "active" : ""}`} onClick={setUserProject}>
      <div className={`${itemData.next_repeat_date ? 'is_repeat' : ''} project__data`}>
        <div className='icons'>
          {itemData.next_repeat_date && <><FontAwesomeIcon icon={faRepeat} /></>}
          <span style={{ color: itemData?.color }}><FontAwesomeIcon icon={faSquare} /></span>
        </div>
        <div className='project__name'>
          {itemData?.name}<br />{itemData.client?.name}<br />
          <div className='project__meta'>
            {dateFormat(itemData?.created_at, "nice")} - {nextDueDate}<br />
            {getHoursMinsStr(itemData, false).str}
          </div>
        </div>
        <div className={'incompletetaskcount ' + tasksclass}>{itemData?.incompletetaskcount}</div>
        <div className={'unconfirmedtaskcount ' + approvedclass}>{itemData?.unconfirmedtaskcount}</div>
        <div className={'readyforinvoicetaskcount ' + invoiceclass}>{itemData?.readyforinvoicetaskcount}</div>

      </div>
    </div>
  )
}

export default function MenuProjects() {
  const { projects } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [thisState, setThisState] = React.useState({});

  let projectslist = projects;

  let filter = state.filter;

  if (filter) {
    let keyword = filter.toLowerCase();
    projectslist = projectslist.filter(function (project) {
      let projectname = project.name.toLowerCase();
      let clientname = project.client?.name.toLowerCase() || "";

      return projectname.indexOf(keyword) > -1 || clientname.indexOf(keyword) > -1;
    });
  }

  if (thisState.repeatjobs) {
    projectslist = projectslist.filter(function (project) {
      return project.hasOwnProperty('next_repeat_date');
    });
  }

  if (thisState.opentimers) {


    projectslist = projectslist.filter(project => project.cards?.some(card => card.tasks?.some(task => task.timesheet?.some(tsheet => tsheet.start_time && !tsheet.stop_time))));

  }

  if (thisState.adhocjobs) {
    projectslist = projectslist.filter(function (project) {
      return !project.hasOwnProperty('next_repeat_date');
    });

  }

  projectslist?.map((project, index) => {
    let cards = project.cards;
    project.nextdue_date = project.due_date;
    project.incompletetaskcount = 0;
    project.unconfirmedtaskcount = 0;
    project.readyforinvoicetaskcount = 0;
    var projectowners = project.organisation?.owners || [];

    let isprojectowner = projectowners.filter(orgowner => orgowner._id === state.user._id).length > 0 ? true : false;

    let account_manager = project.account_manager;
    let creator = project.creator;

    cards?.map((card, index) => {

      card.tasks?.map((task, index) => {

        if (!task.completed_at) {
          // task not completed
          // user is assigned on the task- or user is the creator of the task or user is the projectowner
          //let isusertask = task.users?.filter(taskuser => taskuser._id === state.user._id).length > 0 ? true : false;
          let isusertask = isMyProjectTask(task, state.user._id);

          if (isusertask || task.creator === state.user._id || isprojectowner) {
            project.incompletetaskcount++;
          }

          if (task.due_date) {
            if (!project.nextdue_date) {
              project.nextdue_date = task.due_date;
            }
            else if (DateDiff(new Date(task.due_date), new Date(project.due_date)) < 0) {
              project.nextdue_date = task.due_date;
            }
          }
        }

        if (!task.confirmed_at && task.completed_at) {
          // task is completed but not approved
          project.unconfirmedtaskcount++
        }

        if ((task.confirmed_at && task.completed_at && !task.invoice_id && (state.user._id == account_manager || projectowners.includes(state.user._id)))) {

          project.readyforinvoicetaskcount++
        }

      })
    })
  })


  if (thisState.invoicejobs) {
    projectslist = projectslist?.filter(function (project) {
      return project.readyforinvoicetaskcount > 0;
    });
  }

  if (thisState.approvejobs) {
    projectslist = projectslist?.filter(function (project) {
      return project.unconfirmedtaskcount > 0;
    });
  }


  // projectslist = projectslist?.filter(function (project) {
  //   var owners = project.organisation?.owners || [];
  //   return (project.creator?._id === state.user._id || owners.includes(state.user._id)) || (project.incompletetaskcount > 0 || project.unconfirmedtaskcount > 0 || project.readyforinvoicetaskcount > 0);
  // });

  if (thisState.todojobs) {

    projectslist = projectslist.filter(function (project) {
      return project.incompletetaskcount > 0;
    });

  }

  projectslist?.sort((a, b) => {
    if (!a.nextdue_date)
      return -2;
    if (a.nextdue_date > b.nextdue_date)
      return 1;
    if (a.nextdue_date < b.nextdue_date)
      return -1;
    return 0;

  });

  return (
    <div className={`projects ${thisState.projectsHidden ? "hidden" : ""} `}>
      <div className='header' >
        <h2 onClick={e => { if (thisState.projectsHidden) { setThisState({ ...thisState, projectsHidden: false }) } else { setThisState({ ...thisState, projectsHidden: true }) } }}>{projectslist?.length || 0} Jobbies <FontAwesomeIcon icon={thisState.projectsHidden ? faChevronUp : faChevronDown} /></h2>
        <div className='buttons'>
          <ButtonGlow onClick={() => { dispatch({ type: 'projCreatorShown', payload: true }) }} icon={faPlus} fontSize='1.5rem' />
          <ButtonGlow onClick={() => { if (thisState.openProjectFilters) { setThisState({ ...state, openProjectFilters: false }) } else { setThisState({ ...state, openProjectFilters: true }) } }} icon={faBars} fontSize='1.5rem' />
        </div>
      </div>
      <div className={`projectfilters ${thisState.openProjectFilters ? "active" : ""}`}>
        <div className='filters'>
          <input type='text' placeholder="Job Name Filter" onKeyUp={e => dispatch({ type: 'setFilter', payload: e.target.value })} />
          <button onClick={() => { dispatch({ type: 'workLoadWindow', payload: !state.workLoadWindow }) }}>Work Load</button>
        </div>
      </div>
      <div className='quickfilters'>
        <button className={`repeat ${thisState.repeatjobs ? "active" : ""}`} onClick={e => { if (thisState.repeatjobs) { setThisState({ ...thisState, repeatjobs: false }) } else { setThisState({ ...thisState, repeatjobs: true }) } }}><FontAwesomeIcon icon={faRepeat} /></button>
        <button className={`repeat ${thisState.opentimers ? "active" : ""}`} onClick={e => { if (thisState.opentimers) { setThisState({ ...thisState, opentimers: false }) } else { setThisState({ ...thisState, opentimers: true }) } }}><FontAwesomeIcon icon={faClock} /></button>
        <button className={`todo ${thisState.todojobs ? "active" : ""}`} onClick={e => { if (thisState.todojobs) { setThisState({ ...thisState, todojobs: false }) } else { setThisState({ ...thisState, todojobs: true }) } }}></button>
        <button className={`toapprove ${thisState.approvejobs ? "active" : ""}`} onClick={e => { if (thisState.approvejobs) { setThisState({ ...thisState, approvejobs: false }) } else { setThisState({ ...thisState, approvejobs: true }) } }}></button>
        <button className={`toinvoice ${thisState.invoicejobs ? "active" : ""}`} onClick={e => { if (thisState.invoicejobs) { setThisState({ ...thisState, invoicejobs: false }) } else { setThisState({ ...thisState, invoicejobs: true }) } }}></button>
      </div>
      <div>
        <List
          classes="projects__list"
          ids="list--projects"
          elements={projectslist}
          itemtemplate={MenuProject}
        />
      </div>
    </div>
  )
}