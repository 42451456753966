import { useContext } from 'react';
import { ProjectsContext, ReducerContext } from 'app';
import axios, { setResponseError } from 'utils/axiosConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';

export default function OptionDelete({ task }) {
  const { projects, setProjects, activeProject, setActiveProject } = useContext(ProjectsContext);
  const { dispatch } = useContext(ReducerContext);

  function deleteTask() {
    const cards = structuredClone(activeProject.cards);
    let cardID = 0;

    let i = 0;
    while (i < cards.length) {
      let t = 0;
      while (t < cards[i].tasks.length) {

        if (cards[i].tasks[t]._id == task._id) {
          cards[i].tasks.splice(t, 1);
          cardID = cards[i]._id;
        } else {
          ++t;
        }
      }
      i++;
    }

    setActiveProject({ ...activeProject, cards });

    dispatch(
      {
        type: 'setEditor',
        payload: { params: null, data: null }
      })


    axios.post('/a/task/delete',
      {
        projectID: activeProject._id,
        taskID: task._id,
        cardID
      })
      .catch(err => {
        setResponseError(err, dispatch);

      })
  }

  return (
    <div className='option option--remove' onClick={deleteTask}>
      <div className='option__icon'><FontAwesomeIcon icon={faMultiply} /></div>
    </div>
  )
}