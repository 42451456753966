import { useContext, useState } from 'react';
import { ProjectsContext, ReducerContext } from 'app';
import axios, { setResponseError, setResponseConfirmation } from 'utils/axiosConfig'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function OptionDeleteList({ type }) {
  return;

  const { activeProject } = useContext(ProjectsContext);
  const { dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);

  function deleteList() {
    let card = type;
    activeProject.cards.map((card, index) => {

    })
    const index = activeProject.cards.findIndex(c => c._id === card._id)

    newActiveProject.cards.splice(index, 1);

    dispatch({ type: 'updateActiveProject', payload: newActiveProject })

    axios.post('/a/project/' + activeProject._id + '/cards/' + card._id + '/delete/',
      {})
      .then(() => {

        setResponseConfirmation("Successfully deleted list", "", dispatch)
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: 'updateActiveProject', payload: activeProject })
      })

    dispatch({ type: 'confirmationShown', payload: false })
  }

  function showConfirmation() {
    dispatch(
      {
        type: 'setConfirmation',
        payload:
        {
          header: "Are you sure you want to delete this task list?",
          message: "This action is not reversible and all tasks inside this list will be lost forever.",
          className: 'btn--confirmation--danger',
          confirmation: "Yes, I want to delete this list",
          rejection: "No, I'm keeping this list",
          function: deleteList
        }
      })

    dispatch({ type: 'confirmationShown', payload: true })
    dispatch({ type: 'setCardOptions', payload: { params: null, data: null } })
  }

  return (
    <div className="option option--danger" onClick={showConfirmation}>
      <FontAwesomeIcon icon={faTrash} />
      <span>Delete list</span>
    </div>
  )
}