import React, { useEffect, useReducer } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import axios, { setResponseError } from 'utils/axiosConfig';

import 'css/app.css';

import { AnimateTransit } from 'components/utils/transitions/transitions';
import { Notification, Confirmation } from 'components/utils/modals/modals'
import NotFoundPage from 'pages/404'
import HomePage from 'pages/home'
import LoginPage from 'pages/login'
import RegisterPage from 'pages/register'
import SettingsPage from 'pages/settings'
import XeroRedir from 'pages/xeroredir'
import Popup from "components/popup/popup"
import WorkLoadWindow from "components/home/workload/workload"

export const UserContext = React.createContext();
export const ProjectsContext = React.createContext();
export const ReducerContext = React.createContext();


export default function App() {
  // const location = useLocation();
  const navigate = useNavigate();


  const [state, dispatch] = useReducer(reducer,
    {
      sort: '',
      user: null,
      data: null,
      clients: null,
      fetchNotifications: false,
      fetchingClients: null,
      projects: null,
      activeProject: null,
      openProject: null,
      fetchData: false,
      fetchingData: false,
      fetchItemCodes: false,
      fetchingItemCodes: false,
      itemCodes: null,
      filters:
      {
        keywords: '',
        date: null,
        tags: []
      },


      menuShown: JSON.parse(localStorage.getItem("menuOpen")) ?? false,
      tagsNameShown: JSON.parse(localStorage.getItem("tagsNameShown")) ?? true,
      projCreatorShown: false,
      menuInvoicesShow: false,

      notifications: [],
      notification: null,
      notificationShown: false,

      confirmation: null,
      confirmationShown: false,

      projOptions: null,
      projTagsEditor: null,
      editor: { params: null, data: null },
      cardOptions: { params: null, data: null },

    });

  function reducer(state, action) {
    var projects
    var invoiceables
    var invoices
    var updatedProject
    var activeProject

    switch (action.type) {
      case 'setSort':
        return { ...state, sort: action.payload }
      // case 'setNotifications':
      //   return { ...state, notifications: action.payload }
      case 'setFilters':
        return { ...state, filters: action.payload }

      case 'fetchingUser':
        return { ...state, fetchingUser: action.payload }

      case 'loadActiveProject':
        return { ...state, loadActiveProject: action.payload }

      case 'gettingProject':
        return { ...state, gettingProject: action.payload }

      case 'fetchNotifications':

        return { ...state, fetchNotifications: action.payload }
      // case "openProjectFilters":
      //   return { ...state, openProjectFilters: action.payload }
      case 'fetchData':
        return { ...state, fetchData: action.payload }
      case 'fetchingClients':
        if (action.payload === true) {
          return { ...state, fetchClients: false, fetchingClients: true }
        }
        else {
          return { ...state, fetchingClients: action.payload }
        }
      case 'fetchClients':
        if (action.payload === true) {
          return { ...state, fetchClients: action.payload, fetchingClients: true }
        }
        else {
          return { ...state, fetchClients: action.payload }
        }

      case 'addClient':
        return { ...state, clients: state.clients.push(action.payload) }
      case 'setClients':
        return { ...state, clients: action.payload, fetchingClients: false }
      case 'getClients':
        return { ...state, getClients: false, fetchClients: true }
      case 'setUser':
        return { ...state, fetchData: true, user: action.payload }

      // ui
      case 'menuShown':
        localStorage.setItem("menuOpen", action.payload)
        return { ...state, menuShown: action.payload };

      case 'tagsNameShown':
        localStorage.setItem("tagsNameShown", action.payload)
        return { ...state, tagsNameShown: action.payload };

      case 'projCreatorShown':
        return { ...state, projCreatorShown: action.payload };

      case 'menuInvoicesShow':
        return { ...state, menuInvoicesShow: action.payload };

      case 'confirmationShown':
        return { ...state, confirmationShown: action.payload }

      case 'setConfirmation':
        return { ...state, confirmation: action.payload };

      case 'notificationShown':
        return { ...state, notificationShown: action.payload }

      case 'setNotifications':
        return { ...state, notifications: action.payload };

      case 'workLoadWindow':
        return { ...state, workLoadWindow: action.payload };

      case 'setNotification':
        return { ...state, notification: action.payload };

      case 'setProjOptions':
        return { ...state, projOptions: action.payload }

      case 'setProjTagsEditor':
        return { ...state, projTagsEditor: action.payload }

      case 'setData':

        let updatedprojects = action.payload.projects;
        let updatedinvoices = action.payload.invoices;
        let updatedinvoiceables = action.payload.invoiceables;
        let users = action.payload.users;
        let item_codes = action.payload.item_codes;


        users = state.users ? state.users : users;
        item_codes = state.item_codes ? state.item_codes : item_codes;

        invoices = state.invoices ? state.invoices.map((invoice, index) => {
          // loop through updatedprojects finding this project id
          updatedinvoices.filter((updatedinvoice) => {
            if (updatedinvoice._id === invoice._id) {
              //project has been updated
              for (const key in updatedinvoice) {
                if (updatedinvoice.hasOwnProperty(key)) {
                  // Check if the value has changed or if the property does not exist in originalObject
                  if (invoice[key] !== updatedinvoice[key]) {
                    invoice[key] = updatedinvoice[key];
                  }
                }
              }
            }
            else
              return invoice;
            // return updatedproject._id === project._id
          })

          return invoice;
        }) : updatedinvoices;

        invoiceables = state.invoiceables ? state.invoiceables.map((invoiceable, index) => {
          // loop through updatedprojects finding this project id
          updatedinvoiceables.filter((updatedinvoiceable) => {
            if (updatedinvoiceable._id === invoiceable._id) {
              //project has been updated
              for (const key in updatedinvoiceable) {
                if (updatedinvoiceable.hasOwnProperty(key)) {
                  // Check if the value has changed or if the property does not exist in originalObject
                  if (invoiceable[key] !== updatedinvoiceable[key]) {
                    invoiceable[key] = updatedinvoiceable[key];
                  }
                }
              }
            }
            else
              return invoiceable
            // return updatedproject._id === project._id
          })

          return invoiceable;
        }) : updatedinvoiceables;

        projects = state.projects ? state.projects.map((project, index) => {
          // loop through updatedprojects finding this project id
          updatedprojects.filter((updatedproject) => {
            if (updatedproject._id === project._id) {
              //project has been updated
              for (const key in updatedproject) {
                if (updatedproject.hasOwnProperty(key)) {
                  // Check if the value has changed or if the property does not exist in originalObject
                  if (project[key] !== updatedproject[key]) {
                    project[key] = updatedproject[key];
                  }
                }
              }
            }
            else
              return project;
            // return updatedproject._id === project._id
          })

          return project;
        }) : updatedprojects;


        return {
          ...state,
          invoices,
          invoiceables,
          //activeProject: action.payload.activeProject, 
          users,
          projects,
          item_codes
        }


      // case 'updateActiveProject':
      //   // projects = state.projects
      //   // invoiceables = state.invoiceables;

      //   dispatch({ type: "updateProject", payload: action.payload })

      //dispatch("updateActiveProject", payload: { project: action.payload })
      // let newproject = action.payload;

      // if (action.payload !== null) {
      //   // loop through invoiceables replacing a projet with this one...
      //   invoiceables = state.invoiceables.map((project, index) => {
      //     return (project._id == newproject._id) ? newproject : project;
      //   });

      //   //loop through projects replacing a project with this one...
      //   projects = state.projects.map((project, index) => {

      //     const newinvoicable = newproject.cards.filter(card => card.tasks.filter(task => task.invoiceable).length);

      //     if (newinvoicable > 0) {
      //       invoiceables.push(newproject);
      //     }


      //     return (project._id == newproject._id) ? newproject : project;
      //   });

      // }


      // return { ...state, projects, invoiceables }

      case 'setCardOptions':
        return { ...state, cardOptions: { params: action.payload.params, data: action.payload.data } }

      case 'setProjects':
        return { ...state, projects: action.payload }

      case 'setFilter':
        return { ...state, filter: action.payload }

      case 'getProject':
        let projectid = action.payload;
        console.log("getProject: ", projectid);

        return { ...state, getProject: projectid }

      case 'updateProject':

        projects = state.projects
        invoiceables = state.invoiceables;
        activeProject = state.activeProject;

        updatedProject = action.payload;


        if (updatedProject !== null) {

          console.log("updateProject...", updatedProject);

          let invoiceableProject = updatedProject.fixed_fee > 0 || updatedProject.cards?.some(card => card.tasks?.some(task => task.invoiceable));

          if (invoiceableProject) {
            //is invoiceable
            if (!invoiceables.some(invoiceable => invoiceable._id == updatedProject._id)) {
              //not found.. add it
              //console.log("project not found in invoiceables... add it")
              invoiceables.push(updatedProject);
              //console.log("new invoiceableds: ", invoiceables);
            }
            else {
              //console.log("project found in invoiceables... update it")
              invoiceables = state.invoiceables.map((project, index) => {
                //found... replace it
                return (project._id === updatedProject._id) ? updatedProject : project;
              });
            }
          }
          else if (!invoiceableProject && invoiceables.some(invoiceable => invoiceable._id === updatedProject._id)) {
            //console.log("project not invoiceable but found in invoiceables... remove it")
            // not an invoiceableProject but found invoiceables...
            invoiceables = invoiceables.filter(invoiceable => invoiceable._id !== updatedProject._id);
          }

          if (!projects.some(project => project._id == updatedProject._id)) {
            // not found in projects list... push it
            console.log("not found in projects... push it ")
            projects.push(updatedProject);
          }
          else {
            // found in projects list... replace it
            if (updatedProject.archived_at || updatedProject.deleted_at) {
              console.log("found in projects but is archived or deleted... so remove it ")
              projects = state.projects.filter(project => project._id != updatedProject._id)
            }
            else {
              console.log("not found in projects... update it ")
              projects = state.projects.map((project, index) => {
                return (project._id === updatedProject._id) ? updatedProject : project;
              });
            }

          }

          if (state.activeProject?._id == updatedProject._id) {
            activeProject = updatedProject;
          }

        }
        console.log("invoiceables: ", invoiceables)
        console.log("projects: ", projects)

        return { ...state, projects, activeProject, invoiceables }

      case 'removeUserNotification':

        let notifications = state.notifications.filter(function (notification) {
          return notification._id !== action.payload
        })

        axios.post('/a/user/update?type=removeNoti', { notiID: action.payload });
        return { ...state, notifications }

      case 'setActiveProjectID':

        if (action.payload) {
          console.log("setActiveProjectID: ", action.payload);
          axios.post('/a/user/update?type=activeProject', { projectID: action.payload })
        }

        return { ...state, activeProjectID: action.payload }

      case 'setActiveProjectTaskID':

        return { ...state, activeProjectTaskID: action.payload }

      case 'setActiveProject':
        return { ...state, activeProject: action.payload }

      case 'openProjectWindow':

        return { ...state, openProject: action.payload }


      case 'setEditor':
        return { ...state, editor: { params: action.payload.params, data: action.payload.data } }

      default: return state;
    }
  }

  // function checkInvoiceables(invoiceables){

  //   var invoiceables = invoiceables.filter((project, index) => {
  //   project.cards.filter(card => card.tasks.filter(task => {return task.invoiceable }))      
  //   });

  //   console.log("checkinvoiceabels: ", invoiceables)
  // return invoiceables;
  // }

  function fetchUser() {

    if (!state.user && !state.fetchingUser) {

      dispatch({ type: 'fetchingUser', payload: true })

      axios.post(`/g/user/get`, { reqType: 'login' })
        .then(res => {
          dispatch({ type: 'fetchingUser', payload: false })
          if (res.data.user) {
            dispatch({ type: 'setUser', payload: res.data.user })
            dispatch({ type: 'workLoadWindow', payload: true })
            // if (res.data.user.activeProject) {
            //   dispatch({ type: "setActiveProjectID", payload: res.data.user.activeProject })
            // }
          }
          else {

            if (window.location.pathname !== "/login") {
              navigate('/login')
            }
          }

        })
        .catch(err => {

          dispatch({ type: 'fetchingUser', payload: false })

          if (window.location.pathname !== "/login") {

            navigate('/login')
          }
          //setResponseError(err, dispatch)
        })
    }

  }

  function fetchClients() {
    if (state.fetchClients && !state.fetchingClients) {

      dispatch({ type: 'fetchingClients', payload: true })

      axios.post('/a/clients/', {})
        .then(r => {
          dispatch({ type: 'setClients', payload: r.data.clients })

        })
        .catch(err => {
          setResponseError(err, dispatch)
        })
    }
  }

  function fetchNotifications() {

    if (state.fetchNotifications) {

      dispatch({ type: 'fetchNotifications', payload: false })
      axios.post('/a/user/notifications', {})
        .then(r => {

          let newNotifications = r.data.notifications.filter(newNotification => !state.notifications.some(noti => noti._id === newNotification._id))

          if (1 == 2) {
            newNotifications.forEach(newNoti => {

              new window.Notification(newNoti.description)
              dispatch(
                {
                  type: 'setNotification',
                  payload:
                  {
                    type: "alert",
                    header: "New Notification",
                    message: newNoti.description
                  }
                })
              dispatch({ type: 'notificationShown', payload: true });

            })
          }



          dispatch({ type: 'setNotifications', payload: r.data.notifications })


        })
        .catch(err => {
          setResponseError(err, dispatch)
        })


    }
  }

  function fetchData() {
    let data = state.data

    if (data == null && state.user !== null && state.fetchData) {
      console.log("fetchData");
      //dispatch({ type: 'fetchData', payload: false })
      dispatch({ type: 'fetchNotifications', payload: true })

      axios.post(`/a/users/${state.user._id}/getdata`,)
        .then(res => {
          // dispatch({ type: 'fetchData', payload: false })
          if (res.data.data) {
            dispatch({ type: "setData", payload: res.data.data })
          }
        })
        .catch(err => { })

    }
    // else {
    //   dispatch({ type: 'fetchData', payload: false })
    // }
  }

  function refetchData() {
    let data = state.data

    dispatch({ type: 'fetchData', payload: false })
    dispatch({ type: 'fetchNotifications', payload: true })

    axios.post(`/a/users/${state.user._id}/refetchdata`,)
      .then(res => {
        dispatch({ type: 'refetchdata', payload: false })
        if (res.data.data) {
          console.log("resetting data");
          dispatch({ type: "setData", payload: res.data.data })
        }


      })
      .catch(err => {

      })


  }

  function getProject() {
    if (state.getProject) {
      console.log("getting project... ", state.getProject);

      //dispatch({ type: 'getProject', payload: false });
      //dispatch({ type: 'setActiveProject', payload: { _id: "loading" } })
      axios.post(`/a/project/${state.getProject}`,)
        .then(res => {
          let project = res.data.project;

          dispatch({ type: 'updateProject', payload: project })
          if (project._id == state.activeProjectID) {
            dispatch({ type: 'setActiveProject', payload: project })
          }
        })
        .catch(err => {
          console.log("error getting project: ", err)
        })
    }
  }

  function loadActiveProject() {

    console.log("loadactiveProjectID: ", state.activeProjectID);
    console.log("state.projects:", state.projects)

    if (state.activeProjectID && state.projects) {

      let activeProject = state.projects?.find(project => project._id === state.activeProjectID);

      if (activeProject) {
        console.log("found activeProject in projects list: ", activeProject);

        activeProject.activeTask = null;
        console.log("looking for activeProject Task: ", state.activeProjectTaskID)
        let activetask = activeProject.cards.filter(card => card.tasks?.filter(task => task._id == state.activeProjectTaskID));

        console.log("activetask: ", activetask)

        dispatch({ type: 'setActiveProject', payload: activeProject })
      }
      else {
        console.log("state.projects", state.projects);

        console.log("couldn't find project in the list... go get it");

        dispatch({ type: 'getProject', payload: state.activeProjectID })

        console.log("state.projects: ", state.projects);

        let activeProject = state.projects?.find(project => project._id === state.activeProjectID);
        console.log("found new activeProject: ", activeProject)
        if (activeProject) {
          dispatch({ type: 'setActiveProject', payload: activeProject })
        }

        //loadActiveProject();
        //dispatch({ type: 'setActiveProject', payload: activeProject })
      }
    }

  }
  useEffect(() => { fetchUser() }, [!state.user]);
  useEffect(() => { fetchClients() }, [state.fetchClients]);
  useEffect(() => { fetchNotifications() }, [state.fetchNotifications]);
  useEffect(() => { fetchData() }, [state.fetchData]);

  useEffect(() => { loadActiveProject() }, [state.activeProjectID])
  useEffect(() => { getProject() }, [state.getProject]) // we've got an ID but not a project

  useEffect(() => // hide notification
  {
    const timer = setTimeout(() => { if (state.notificationShown) dispatch({ type: 'notificationShown', payload: false }) }, 5000);
    return () => { clearTimeout(timer) };
  }, [state.notificationShown])


  useEffect(() => // timer to refetch data every 60s
  {
    const timer = setTimeout(() => { refetchData() }, 60000);
    return () => { clearTimeout(timer) };
  })


  // notifications

  if (!("Notification" in window)) {
    alert("This browser does not support system notifications!")
  }

  else if (typeof (window.Notification.permission) == "undefined" || window.Notification.permission == "default") {
    window.Notification.requestPermission((permission) => {
      if (permission === "granted") {

        dispatch(
          {
            type: 'setNotification',
            payload:
            {
              type: "success",
              header: "Notifications Granted",
              message: "Thanks! You will receive browser notifications!"
            }
          })
      }
    })
  }

  //the current activeprojectID 
  // if (state.activeProjectID && state.activeProjectID !== state.activeProject?._id) {
  //   console.log("state.activeProjectID: ", state.activeProjectID, state.loadActiveProject);
  //   dispatch("loadActiveProject", { payload: state.activeProjectID })
  // }
  return (
    <div className="app" id='app'>
      <UserContext.Provider value={{ user: state.user }}>
        <ProjectsContext.Provider value={{ projects: state.projects, invoices: state.invoices, invoiceables: state.invoiceables, activeProject: state.activeProject }}>
          <ReducerContext.Provider value={{ state, dispatch }}>
            <AnimateTransit children={state.notificationShown && <Notification />} />
            <AnimateTransit children={state.confirmationShown && <Confirmation />} />
            {state.workLoadWindow && <>
              <Popup hide={() => { dispatch({ type: "workLoadWindow", payload: null }) }}>
                <WorkLoadWindow></WorkLoadWindow>
              </Popup>
            </>}
            <Routes>
              <Route exact path='/xero-redir' element={<XeroRedir />} />
              <Route exact path='/' element={<HomePage />} />

              <Route path='/login' element={<LoginPage />} />
              <Route path='/register' element={<RegisterPage />} />
              <Route exact path='/settings' element={<SettingsPage />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </ReducerContext.Provider>
        </ProjectsContext.Provider>
      </UserContext.Provider>
    </div>
  );
}
