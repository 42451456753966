import { useContext } from "react";
import { ReducerContext } from "app";

import { TransitionOpacity } from "components/utils/transitions/transitions";
import OptionSort from "./options/option-sort";
import OptionFilter from "./options/option-filter";
import OptionMoveTasks from "./options/option-tasks-move";
import OptionCopyTasks from "./options/option-tasks-copy";
import OptionArchiveTasks from "./options/option-tasks-archive";
import OptionDeleteCard from "./options/option-card-delete";
import OptionMoveList from "./options/option-list-move";
import OptionCloneList from "./options/option-list-clone";
import OptionArchiveList from "./options/option-list-archive";
import OptionDeleteList from "./options/option-list-delete";

export default function CardOptions() {
  const { state, dispatch } = useContext(ReducerContext);

  const optionsStyle =
  {
    left: state.cardOptions.params?.x ?? 0,
    top: state.cardOptions.params?.y ?? 0,
  }

  function hideOptions() {
    dispatch(
      {
        type: 'setCardOptions',
        payload:
        {
          params: null,
          data: null
        }
      })
  }

  return (
    <TransitionOpacity className="overlay__bg--light" onClick={hideOptions} id='card-options'>
      {
        state.cardOptions.data &&
        <div className='card__options' style={optionsStyle} onClick={e => { e.stopPropagation() }}>

          <OptionDeleteList type={state.cardOptions.data} />

        </div>
      }
    </TransitionOpacity>
  )
}