import React, { useState, useContext, useRef } from 'react';
import axios, { setResponseError } from 'utils/axiosConfig';
import { ProjectsContext, ReducerContext } from "app";
import QuillEditor from 'components/utils/quill.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { dateFormat, getHoursMinsStr, DateOptions, ControlledInput } from "../../../../../utils/utils";

export function TaskDetails(props) {
  const { projects, activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);
  const [task, setTask] = useState(props.task);

  const project = activeProject;


  let taskauthor = state.users.filter(user => { return user._id == task.creator });
  taskauthor = taskauthor ? taskauthor[0] : [];

  const card = project.cards.find(card => card.tasks?.some(cardtask => cardtask._id == task._id));

  function deleteTask() {
    console.log("activeProject.activeTask?._id", activeProject.activeTask?._id, " = ", task._id);
    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks = c.tasks.filter(t => {
          return task._id != t._id;
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards, activeTask: null } });

    if (activeProject.activeTask?._id == task._id) {
      dispatch({ type: "setActiveProjectTaskID", payload: null })
    }


    axios.post('/a/task/' + task._id + '/delete', {})
      .catch(err => {
        setResponseError(err, dispatch)
        dispatch({ type: "updateProject", payload: activeProject })
      });
  }

  function handleSave(e) {
    let thisname = e.target.name;
    if (thisname == "name") {
      setName(e);
    }
    if (thisname == "description") {
      setDescription(e);
    }
    if (thisname == "est_hours") {
      setEstHours(e)
    }
  }

  function setConfirmComplete(confirm) {
    let thisconfirm = (confirm) ? "Just Now" : null;

    task.confirmed_at = thisconfirm;

    const cards = newActiveProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task

            t.confirmed_at = thisconfirm;
            if (!t.completed_at) {
              t.completed_at = thisconfirm
            }
            else {
              t.completed_at = t.completed_at
            }

          }
        })
      }
      return c;
    })


    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=confirmcomplete',
      {
        confirmcomplete: confirm
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setTaskRepeats(confirm) {

    task.repeats = confirm;

    const cards = newActiveProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.repeats = confirm;
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=repeats',
      {
        repeats: confirm
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setTaskInvoiceable(confirm) {

    task.invoiceable = confirm;

    const cards = newActiveProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.invoiceable = confirm;
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=invoiceable',
      {
        invoiceable: confirm
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setComplete(iscomplete) {
    let thiscomplete = (iscomplete) ? "Just Now" : null;

    task.completed_at = thiscomplete;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task

            t.completed_at = thiscomplete;
            if (!thiscomplete) {
              t.confirmed_at = null
            }
            else {
              t.confirmed_at = t.confirmed_at
            }

          }
        })
      }
      return c;
    })
    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=completed',
      {
        iscomplete: iscomplete
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setName(e) {

    let newName = e.target.value;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.name = newName
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, settask_name: false })
    axios.post('/a/task/' + task._id + '/update?type=name',
      {
        name: newName
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function matchesEl(el) {
    return el.value === '14' && el.label === '7';
  }

  function setCard(e) {

    let oldProjects = state.projects;

    let oldCardId = card._id;
    let newCard = e.target.value;

    task.card = newCard;

    let projects = oldProjects.map(project => {
      project.cards.map(projectcard => {
        //remove the task from the old card
        if (projectcard._id == oldCardId) {
          projectcard.tasks = projectcard.tasks?.filter(projectTask => { return (projectTask._id !== task._id) })
        }
        // insert the task into the new card
        if (projectcard._id == newCard) {
          projectcard.tasks.push({ ...task, card: projectcard._id });
        }
        project.tasks = projectcard.tasks
      });

      return project;

    });

    dispatch({ type: "setProjects", payload: projects });

    setTask({ ...task, setCard: false })
    axios.post('/a/task/' + task._id + '/update?type=card',
      {
        card: newCard
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "setProjects", payload: oldProjects })
      })
  }

  function setDescription(e) {

    let newDescription = e.target.value;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.description = newDescription

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, settask_description: false })
    axios.post('/a/task/' + task._id + '/update?type=description',
      {
        description: newDescription
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setDueDate(e) {

    task.due_date = e.target.value;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.due_date = task.due_date;
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, setduedate: false })
    axios.post('/a/task/' + task._id + '/update?type=due_date',
      {
        due_date: task.due_date
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function setEstHours(e) {

    task.est_hours = e.target.value;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task

            t.est_hours = task.est_hours;

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, setesthours: false })
    axios.post('/a/task/' + task._id + '/update?type=est_hours',
      {
        est_hours: task.est_hours
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function saveTaskDescription(e) {
    let newDescription = task.description;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.description = newDescription

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, settask_description: false })
    axios.post('/a/task/' + task._id + '/update?type=description',
      {
        description: newDescription
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateProject", payload: activeProject })
      })
  }

  function addComment(e) {


    let newComment = task.newComment;
    task.newComment = "";


    let taskcomments = task.comments || [];
    if (newComment) {

      let newCommentContent = { _id: -1, content: newComment, created_at: "Just Now", user: [{ name: "You" }] };
      taskcomments.push(newCommentContent)
      task.comments = taskcomments;

      const cards = activeProject.cards.map(c => {
        if (c._id === task.card) { // we've found the card
          c.tasks.map(t => {
            if (t._id == task._id) {// we've found the task
              t = task;
            }
          })
        }
        return c;
      })


      dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

      axios.post('/a/task/' + task._id + '/update?type=addcomment',
        {
          newcomment: newComment
        })
        .then(r => {
          let newcommentid = r.data._id;


          const cards = activeProject.cards.map(c => {
            if (c._id === task.card) { // we've found the card
              c.tasks.map(t => {
                t = task;
                if (t._id == task._id) {// we've found the task
                  task.comments.map(comment => {
                    if (comment._id == -1) {
                      comment._id = newcommentid
                    }
                  })
                }
              })
            }
            return c;
          })

          dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
        })
        .catch(err => {
          setResponseError(err, dispatch)
        })

      task.newComment = "";
    }
    setTask({ ...task, newComment: null })

  }

  function updateComment(e) {

    let editcomment = task.editcomment;
    let editcommentid = task.editcommentid;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          t = task;
          if (t._id == task._id) {// we've found the task
            task.comments.map(comment => {
              if (comment._id == editcommentid) {
                comment.content = editcomment
              }
            })
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=updatecomment',
      {
        commentid: task.editcommentid,
        content: editcomment
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

    setTask({ ...task, editcomment: "", editcommentid: null })
  }

  function deleteComment(e) {


    let editcommentid = task.editcommentid;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {

          if (t._id == task._id) {// we've found the task
            t.comments = task.comments.filter(comment => comment._id !== editcommentid)
            task.comments = t.comments;

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=deletecomment',
      {
        commentid: task.editcommentid,
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

    setTask({ ...task, editcomment: "", editcommentid: null })
  }

  function addNewResource(e) {

    let newResource = { url: task.newresource_url, description: task.newresource_desc, created_at: "Just Now" };

    task.resources.push(newResource);
    let index = task.resources.length - 1

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.resources = task.resources
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })
    setTask({ ...task, setresourcelink: false })
    axios.post('/a/task/' + task._id + '/update?type=addresource',
      {
        newResource: newResource
      })
      .then(r => {
        task.resources[index]._id = r.data._id;
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

    setTask({ ...task, newresource_desc: "", newresource_url: "" })

  }

  function addTimeSheet(e) {
    //dateFormat(new Date,"yyyy-MM-dd")
    task.newtimesheet_date = task.newtimesheet_date ? task.newtimesheet_date : new Date();
    task.newtimesheet_hours = task.newtimesheet_hours ? task.newtimesheet_hours : 0;
    task.newtimesheet_mins = task.newtimesheet_mins ? task.newtimesheet_mins : 0;

    let newTimeSheet = { description: task.newtimesheet_description, date: task.newtimesheet_date, hours: task.newtimesheet_hours, mins: task.newtimesheet_mins, created_at: "Just Now", user: [{ name: "You" }] };
    let timesheet = task.timesheet || [];
    timesheet.push(newTimeSheet)
    task.timesheet = timesheet;

    let index = task.timesheet.length - 1;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.timesheet = task.timesheet
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=addtimesheet',
      {
        newTimeSheet: newTimeSheet
      })
      .then(r => {

        let newid = r.data._id;

        task.timesheet[index]._id = newid;
        task.newtimesheet_date = dateFormat(new Date, "yyyy-MM-dd");
        task.newtimesheet_description = "";
        task.newtimesheet_hours = 0;
        task.newtimesheet_mins = 0;

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

  }

  function startTimer(e) {

    let startTime = new Date();

    console.log("startTimer: ", task);

    task.newtimesheet_date = task.newtimesheet_date ? task.newtimesheet_date : new Date();

    task.edittimesheet_description = task.starttimesheet_description;

    let newTimeSheet = { start_time: startTime, description: task.starttimesheet_description, date: task.newtimesheet_date, hours: 0, mins: 0, created_at: "Just Now", user: [{ name: "You" }] };
    let timesheet = task.timesheet || [];
    timesheet.push(newTimeSheet)
    task.timesheet = timesheet;
    task.starttimesheet_description = "";
    let index = task.timesheet.length - 1;

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.timesheet = task.timesheet
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=addtimesheet',
      {
        newTimeSheet: newTimeSheet
      })
      .then(r => {

        let newid = r.data._id;

        task.timesheet[index]._id = newid;
        task.newtimesheet_date = dateFormat(new Date, "yyyy-MM-dd");
        task.newtimesheet_description = "";
        task.newtimesheet_hours = 0;
        task.newtimesheet_mins = 0;

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

  }

  function updateTimeSheet(index) {

    let timesheet = task.timesheet[index];

    timesheet.description = task.edittimesheet_description;
    timesheet.start_time = task.edittimesheet_start_time || 0;
    timesheet.stop_time = task.edittimesheet_stop_time || 0;


    let startTime = new Date(timesheet.start_time);
    let stopTime = new Date(timesheet.stop_time);

    var diffMs = (stopTime - startTime);
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    console.log("start: ", startTime)
    console.log("start: ", stopTime)
    console.log("diffHrs: ", diffHrs, " diffMins: ", diffMins);

    timesheet.hours = (timesheet.start_time) ? diffHrs : task.edittimesheet_hours;
    timesheet.mins = (timesheet.start_time) ? diffMins : task.edittimesheet_mins;

    console.log("timesheet.hours : ", timesheet.hours);
    console.log("timesheet.mins : ", timesheet.mins);

    task.timesheet[index] = timesheet;
    setTask({ ...task, edittimesheet: -1 });

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            console.log("t", t);
            t.timesheet?.map(tsheet => {
              if (tsheet._id == timesheet._id) { // weve foudn the timesheet
                tsheet = timesheet;
              }
            })

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=updatetimesheet',
      {
        timesheet
      })
      .then(r => {

        let newid = r.data._id;

        task.timesheet[index]._id = newid;
        task.newtimesheet_date = dateFormat(new Date, "yyyy-MM-dd");
        task.newtimesheet_description = "";
        task.newtimesheet_hours = 0;
        task.newtimesheet_mins = 0;

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

  }

  function stopTimer(index) {

    let timesheet = task.timesheet[index];

    let startTime = new Date(timesheet.start_time);
    let stopTime = new Date();


    var diffMs = (stopTime - startTime);
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    timesheet.start_time = startTime;
    timesheet.stop_time = stopTime;
    timesheet.hours = diffHrs;
    timesheet.mins = diffMins;
    //timesheet.description = task.edittimesheet_description;

    console.log("timesheet: ", timesheet);

    task.timesheet[index] = timesheet;


    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            console.log("t", t);
            t.timesheet?.map(tsheet => {
              if (tsheet._id == timesheet._id) { // weve foudn the timesheet
                tsheet = timesheet;
              }
            })

          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    console.log("update timesheet: ", timesheet)
    axios.post('/a/task/' + task._id + '/update?type=updatetimesheet',
      {
        timesheet
      })
      .then(r => {

        let newid = r.data._id;

        task.timesheet[index]._id = newid;
        task.newtimesheet_date = dateFormat(new Date, "yyyy-MM-dd");
        task.newtimesheet_description = "";
        task.newtimesheet_hours = 0;
        task.newtimesheet_mins = 0;

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

  }

  function deleteTimeSheet(index) {

    let tasktimesheet = task.timesheet;

    let timesheetID = task.timesheet[index]._id;

    tasktimesheet.splice(index, 1);

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.timesheet = tasktimesheet
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    if (timesheetID) {
      axios.post('/a/task/' + task._id + '/update?type=deletetimesheet',
        {
          timesheetid: timesheetID
        })
        .catch(err => {
          setResponseError(err, dispatch)
        })

    }

  }

  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave(e);
  }

  function toggleUser(userid) {
    let index = task.users?.findIndex(taskuser => taskuser == userid);

    let users = task.users || [];
    if (index > -1) {

      users.splice(index, 1)
    }
    else {
      //add this user
      let projectuserindex = newActiveProject.users?.findIndex(projectuser => projectuser._id == userid)

      users.push(userid)
    }

    const cards = activeProject.cards.map(c => {
      if (c._id === task.card) { // we've found the card
        c.tasks.map(t => {
          if (t._id == task._id) {// we've found the task
            t.users = users
          }
        })
      }
      return c;
    })

    dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

    axios.post('/a/task/' + task._id + '/update?type=usertoggle',
      {
        userid
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
    //add remove use
  }

  let actHours = task.timesheet?.reduce((actHours, timesheet) => (
    actHours += parseFloat(timesheet.hours)
  ), 0);

  let actMins = task.timesheet?.reduce((actMins, timesheet) => (
    actMins += parseFloat(timesheet.mins)
  ), 0);

  let minHours = 0;
  if (actMins > 60) {
    minHours = Math.floor(actMins / 60);
  }

  actHours += minHours
  actMins -= (minHours * 60)
  let actHoursStr = actHours + "h " + actMins + "mins"

  let creator = state.users.filter(user => { return user._id == task.creator });
  creator = Array.isArray(creator) ? creator[0] : {};
  //console.log("task", task)
  //console.log("task.users: ", task.users);
  //console.log("taskauthor: ", taskauthor);
  if (taskauthor) {
    if (task.users?.filter(user => user._id == taskauthor._id).length <= 0) {
      //console.log("pushing author to user")
      task.users.push(taskauthor);
      //console.log("task.users: ", task.users);
    } else {
      //console.log("author is user")
    }
  }


  let hastimerrunning = task.timesheets?.filter((timesheet) => timesheet.start_time && !timesheet.stop_time).length > 0 ? true : false;



  return (
    <>
      <div id="taskdetails" style={{ maxWidth: "98%" }}>
        <div className='row g-3'>
          <div className='col-sm-6'>

            {!task.settask_name &&
              <>
                <h1><a onClick={e => setTask({ ...task, settask_name: true })}>{task.name ? task.name : "set task name"}</a></h1>
              </>}
            {task.settask_name &&
              <>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={task.name}
                  style={{ width: '100%' }}
                  onChange={e => { setTask({ ...task, name: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}
            {!task.settask_description &&
              <><hr />
                <div onClick={e => setTask({ ...task, settask_description: true })} className="comment" dangerouslySetInnerHTML={{ __html: task.description || "set task description" }} ></div>
              </>}
            {task.settask_description &&
              <>
                <QuillEditor users={task.users} value={task.description} onChange={(e) => { setTask({ ...task, description: e }) }} />
                <a className='btn btn-primary text-right' onClick={e => { saveTaskDescription(); }} >Save Description</a>
              </>}
            <br /><b>Resource Links</b> - <a onClick={e => setTask({ ...task, settask_resourcelink: true })}>Add</a> <br />
            {task.resources?.map((resource, index) => {
              return (
                <div className='row' key={index}>
                  <div className='col-sm-12'>
                    <b>{resource.description}</b><br />{resource.url}</div>
                </div>
              )
            })}

            {task.settask_resourcelink &&
              <>
                <input
                  id="newresource_desc"
                  name="newresource_desc"
                  type="text"
                  placeholder="Resource Description"
                  className="form-control"
                  value={task.newresource_desc}
                  style={{ width: '100%' }}
                  onChange={e => { setTask({ ...task, newresource_desc: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
                <input
                  id="newresource_url"
                  name="newresource_url"
                  type="text"
                  placeholder="Resource URL"
                  className="form-control"
                  value={task.newresource_url}
                  style={{ width: '100%' }}
                  onChange={e => { setTask({ ...task, newresource_url: e.target.value }) }}

                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
                <br />
                <button type="submit" onClick={e => addNewResource()} className="btn btn-primary text-right" name="save_newresource" value="Add" >Add</button>
                <a onClick={e => setTask({ ...task, settask_resourcelink: false })}>Cancel</a>
              </>}

          </div>
          <div className='col-sm-6'>
            <b>Created: </b> {dateFormat(task.created_at, "nice")} | {creator?.name}<br />
            <b>Card: </b>
            {!task.setCard && <a onClick={e => setTask({ ...task, setCard: true })}>{card?.name} </a>}
            {task.setCard && <><select value={card._id} onChange={e => { setCard(e) }}>{
              project.cards.map(card => {
                return (<option value={card._id}>{card.name}</option>)
              })
            }</select></>}<br />
            <b>Est Hours: </b>
            {!state.setesthours &&
              <>
                <a onClick={e => setTask({ ...task, setesthours: true })}>{task.est_hours ? task.est_hours : "set estimated hours"}</a>
              </>}
            {task.setesthours &&
              <>
                <input
                  id="est_hours"
                  name="est_hours"
                  type="number"
                  step="0.5"
                  className="form-control"
                  value={task.est_name}
                  style={{ width: '100%' }}
                  onChange={e => { setTask({ ...task, est_name: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}
            <br /><b>Act Hours: </b> {getHoursMinsStr(task, false).str}<br />

            <b>Due Date: </b>
            {!task.setduedate &&
              <>
                <a onClick={e => setTask({ ...task, setduedate: true })}>{task.due_date ? dateFormat(task.due_date, "nice") : "set due date"}</a>
              </>}
            {task.setduedate &&
              <>
                <input type="date" onBlur={e => setDueDate(e)} name="due_date" />
              </>}
            <br />
            <b>Completed: </b> {task.completed_at && <>
              <a onClick={e => setComplete(false)}>{task.completed_at != "Just Now" ? dateFormat(task.completed_at, "nice") : "Just Now"} - Mark as Not Complete</a>
            </>}{!task.completed_at && <><a onClick={e => setComplete(true)}>Mark As Complete</a></>}
            {task.completed_at && <>
              <br /><b>Completion Approved:</b> {task.confirmed_at && <>
                <a onClick={e => setConfirmComplete(false)}>{task.confirmed_at != "Just Now" ? dateFormat(task.confirmed_at, "nice") : "Just Now"} - Mark as Not Approved</a>
              </>}
              {!task.confirmed_at && <>
                <a onClick={e => setConfirmComplete(true)}>Approve Now</a>
              </>}
            </>}
            {
              project.repeat_str && <>
                <br /><b>Task Repeats:</b> {task.repeats &&
                  <a onClick={e => setTaskRepeats(false)}>Yes</a>
                }
                {!task.repeats &&
                  <a onClick={e => setTaskRepeats(true)}>No</a>
                }
              </>
            }


            <br /><b>Requires Invoice:</b> {task.invoiceable &&
              <a onClick={e => setTaskInvoiceable(false)}>Yes</a>
            }
            {!task.invoiceable &&
              <a onClick={e => setTaskInvoiceable(true)}>No</a>
            }
            {
              task.invoiceable &&
              <>
                <br /><b>Task Invoiced:</b> {task.invoice_id ? "Yes" : "No"}
              </>
            }

            {
              !task.deleteTask && !task.invoice_id && <>
                <a className='btn btn-danger text-right' onClick={e => { setTask({ ...task, deleteTask: true }) }} >Delete Task</a>
              </>
            }
            {
              task.deleteTask && <>
                <a className='btn btn-danger text-right' onClick={e => deleteTask()} >Confirm Delete Task</a>
              </>
            }
            <div className='taskusers'>
              <b>Users: </b>
              {
                newActiveProject.users?.map((user, index) => {
                  return (<>
                    <div className='row' key={index}>
                      <div className='col-sm-1'><input type="checkbox" value={user._id} disabled={task.creator?._id == user._id ? true : false} onChange={e => { toggleUser(user._id) }} checked={task.creator?._id == user._id ? true : task.users?.find(taskuser => (taskuser._id == user._id || taskuser == user._id))} /></div>
                      <div className='col-sm-11'>{user.name}</div>
                    </div>
                  </>)
                })
              }
            </div>
          </div>
        </div>
        <hr />
        <div className='row g-3'>
          <div className='col-sm-6'>
            <div className="comments">
              <b>Comments</b><br />
              {
                task.comments?.length <= 0 && <>No comments</>
              }
              {
                task.comments?.map((comment, index) => {
                  return (
                    <div className='row' key={index}>
                      <div className='col-sm-12'>
                        {comment.created_at != "Just Now" ? dateFormat(comment.created_at, "nice") : "Just Now"}<br />
                        {comment.user[0].name}<br />
                        {
                          task.editcommentid && task.editcommentid == comment._id &&
                          <>
                            <QuillEditor users={task.users} value={comment.content} onChange={(e) => { setTask({ ...task, editcomment: e }) }} />

                            <a className='btn btn-primary text-right' onClick={e => { updateComment(); }} >Update Comment</a>
                            <a className='btn btn-danger text-right' onClick={e => { deleteComment(); }} >Delete Comment</a>
                            <hr />
                          </>
                        }
                        {
                          (task.editcommentid !== comment._id) &&
                          <>
                            <div onClick={e => { if (comment.user[0]._id == state.user._id) { setTask({ ...task, editcommentid: comment._id }) } }} className="comment" dangerouslySetInnerHTML={{ __html: comment.content }} ></div><hr />
                          </>

                        }

                      </div>
                    </div>

                  )
                })
              }
            </div>
            <hr />
            <b>New Comment</b>
            <QuillEditor users={task.users} value={task.newComment} onChange={(e) => { setTask({ ...task, newComment: e }) }} />
            <a className='btn btn-primary text-right' onClick={e => { addComment(); }} >Save Comment</a>
          </div>
          <div className='col-sm-6'>
            <div className='row'>
              <b>Time Sheets</b><br />
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    task.timesheet?.length <= 0 && <>No Time Sheets</>
                  }
                  {



                    task.timesheet?.map((timesheet, index) => {
                      let running = timesheet.start_time && !timesheet.stop_time ? true : false;

                      if (running) {
                        let startTime = new Date(timesheet.start_time);
                        let stopTime = new Date();

                        var diffMs = (stopTime - startTime);
                        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                      }
                      return (
                        <>
                          <tr key={index}>
                            <td ><a onClick={e => { setTask({ ...task, edittimesheet: index, edittimesheet_start_time: dateFormat(timesheet.start_time, "Y-m-d H:i:s"), edittimesheet_stop_time: dateFormat(timesheet.stop_time, "Y-m-d H:i:s"), edittimesheet_hours: timesheet.hours, edittimesheet_mins: timesheet.mins, edittimesheet_description: timesheet.description }) }} >{timesheet.user[0].name}</a></td>
                            <td >{dateFormat(timesheet.date, "nice")}</td>
                            <td>

                              {running && <> {dateFormat(timesheet.start_time, "Y-m-d H:i:s")} - {diffHrs + "h " + diffMins + "m"}<a className='btn btn-primary text-right ' onClick={e => { stopTimer(index) }} >Stop Timer</a></>}

                              {task.edittimesheet == index && <>
                                {
                                  timesheet.start_time && <>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                      <div style={{ display: "flex", }}>
                                        <input
                                          id="start_time"
                                          name="start_time"
                                          type="text"
                                          placeholder="Start"
                                          className="form-control"
                                          value={dateFormat(task.edittimesheet_start_time, "Y-m-d H:i:s")}
                                          style={{ width: '200px' }}
                                          onChange={e => { setTask({ ...task, edittimesheet_start_time: e.target.value }) }}
                                        />
                                        -
                                        <input
                                          id="stop_time"
                                          name="stop_time"
                                          type="text"
                                          placeholder="Stop"
                                          className="form-control"
                                          value={task.edittimesheet_stop_time}
                                          style={{ width: '200px' }}
                                          onChange={e => { setTask({ ...task, edittimesheet_stop_time: e.target.value }) }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                }
                                {
                                  !timesheet.start_time && <>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                      <div style={{ display: "flex", }}>
                                        <input
                                          id="hours"
                                          name="hours"
                                          type="text"
                                          placeholder="Hours"
                                          className="form-control"
                                          value={task.edittimesheet == index ? task.edittimesheet_hours : timesheet.hours}
                                          style={{ width: '50px' }}
                                          onChange={e => { setTask({ ...task, edittimesheet_hours: e.target.value }) }}
                                        />hours
                                      </div>

                                      <div style={{ display: "flex", }}>
                                        <input
                                          id="mins"
                                          name="mins"
                                          type="text"
                                          placeholder="Mins"
                                          className="form-control"
                                          value={task.edittimesheet == index ? task.edittimesheet_mins : timesheet.mins}
                                          style={{ width: '50px' }}
                                          onChange={e => { setTask({ ...task, edittimesheet_mins: e.target.value }) }}
                                        />mins
                                      </div>
                                    </div>
                                  </>
                                }

                              </>}
                              {
                                !running && task.edittimesheet !== index && <>
                                  {timesheet.hours}h {timesheet.mins}min
                                </>
                              }
                            </td>
                            <td className='textright'><a onClick={e => { deleteTimeSheet(index) }} className="btn btn-danger"><FontAwesomeIcon icon={faXmark} /></a></td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              {
                                (task.edittimesheet == index) && <>
                                  <input
                                    id="description"
                                    name="description"
                                    type="text"
                                    placeholder="Description"
                                    className="form-control"
                                    value={task.edittimesheet_description}
                                    style={{ width: '100%' }}
                                    onChange={e => { setTask({ ...task, edittimesheet_description: e.target.value }) }}
                                  />
                                </>
                              }
                              {task.edittimesheet !== index && <>{timesheet.description}</>}
                              {task.edittimesheet == index && <><a className='btn btn-primary text-right ' onClick={e => { updateTimeSheet(index) }} >Save</a></>}
                            </td>
                          </tr>
                        </>

                      )
                    })}
                  {
                    !hastimerrunning &&
                    <tr>
                      <td colSpan={4}>
                        {(!task.edittimesheet || task.edittimesheet == -1) && <>
                          <hr />
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <input
                              id="description"
                              name="description"
                              type="text"
                              placeholder="Description"
                              className="form-control"
                              value={task.starttimesheet_description}
                              style={{ width: '80%' }}
                              onChange={e => { setTask({ ...task, starttimesheet_description: e.target.value }) }}
                            />

                            <a className='btn btn-primary text-right ' onClick={e => { startTimer() }} >Start Time</a>
                          </div>
                        </>}
                      </td>
                    </tr>
                  }

                </tbody>
              </table>
              <hr />
              <b>Add Timesheet</b>
              <div className='row'>
                <div className='col-sm-6'>
                  <input
                    id="date"
                    name="date"
                    type="date"
                    placeholder="Date"
                    className="form-control"
                    value={task.newtimesheet_date || dateFormat(new Date, "yyyy-MM-dd")}
                    style={{ width: '100%' }}
                    onChange={e => { setTask({ ...task, newtimesheet_date: e.target.value }) }}
                  />

                </div>
                <div className='col-sm-3'>
                  <input
                    id="hours"
                    name="hours"
                    type="text"
                    placeholder="Hours"
                    className="form-control"
                    value={task.newtimesheet_hours}
                    style={{ width: '100%' }}
                    onChange={e => { setTask({ ...task, newtimesheet_hours: parseFloat(e.target.value) }) }}
                  />
                </div>
                <div className='col-sm-3'>
                  <input
                    id="mins"
                    name="mins"
                    type="text"
                    placeholder="Mins"
                    className="form-control"
                    value={task.newtimesheet_mins}
                    style={{ width: '100%' }}
                    onChange={e => { setTask({ ...task, newtimesheet_mins: parseFloat(e.target.value) }) }}
                  />
                </div>
                <div className='col-sm-12'>
                  <input
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Description"
                    className="form-control"
                    value={task.newtimesheet_description}
                    style={{ width: '100%' }}
                    onChange={e => { setTask({ ...task, newtimesheet_description: e.target.value }) }}
                  />
                </div>



                <div className="col-sm-12"><a className='btn btn-primary text-right' onClick={e => { addTimeSheet() }} >Save Time</a></div>

              </div>
            </div>
          </div>
        </div>
      </div >

      <div className='row g-3'>
        <div className='col-sm-12'>

        </div>

      </div>

    </>
  )
}