import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@mui/material/Icon';

function ButtonGlow({ onClick, icon, materialicon, fontSize = "2.3rem" })
{
  
  return (
    <div className='btn btn--glow' onClick={ onClick } style={{ fontSize: fontSize }}>
      {
        materialicon && <Icon>{materialicon}</Icon>
      }
      
      {
        icon && <FontAwesomeIcon icon={ icon }/>
      }
      
    </div>
  )
}

export { ButtonGlow };