import { useContext } from 'react';
import { UserContext, ReducerContext } from 'app';
import axios, { setResponseError } from 'utils/axiosConfig';

import { ButtonGlow } from 'components/utils/buttons/buttons';

import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
export default function MenuUser()
{
  const { state, dispatch } = useContext(ReducerContext);
let user = state.user
  function logout()
  {
    axios.post(`/g/user/logout`, 
    {})
    .then(() => 
    {
      window.location.reload()
      
    })
    .catch(err => setResponseError(err, dispatch))
  }

  function showConfirmation()
  {
    dispatch(
    { 
      type: 'setConfirmation',
      payload: 
      {
        header: "Are you sure you want to log out?",
        message: "",
        className: 'btn--confirmation--neutral',
        confirmation: "Yes, I want to log out",
        rejection: "No, I'll keep logged in",
        function: logout
      } 
    })

    dispatch({ type: 'confirmationShown', payload: true })
  }

  return (
    <div className='user'>
      <a className='user__data' href='/'>
        <img className='user__img' src='img/capybara.jpg' alt='user_pic'></img>
        <div className='user__name'>{ user?.name }</div>
      </a>
      <SettingsIcon className="button" color="primary" alt="Settings" onClick={ e => {window.location="/settings"} } />
      <LogoutIcon className="button" color="primary" alt="Logout" onClick={ showConfirmation} />
    
    
    </div>
  )
}