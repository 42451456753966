import { useContext } from "react"
import { ReducerContext } from "app"
import {Loading} from "../../../utils/loading/loading";

export function Screensaver()
{
  return (
    <div className="screensaver">
      <Loading />
    </div>
  )
}

export function NoActiveProject()
{
  const { dispatch } = useContext(ReducerContext);

  function showProjects()
  { dispatch({ type: 'menuShown', payload: true }) }

  function showProjectCreator()
  { dispatch({ type: 'projCreatorShown', payload: true }) }

  return (
    <div className="screensaver">
      <div className="screensaver__header">No active project</div>
      <div className="screensaver__btns">

        <div className="screensaver__btn screensaver__btn--create" onClick={ showProjectCreator }>CREATE A NEW PROJECT</div>
      </div>
      
    </div>
  )
}