import { useContext } from "react"
import { ProjectsContext, UserContext, ReducerContext } from "app"

import cloneProject from 'operations/project-clone'
import deleteProject from 'operations/project-delete';
import archiveProject from 'operations/project-archive';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faArrowDownWideShort, faTag, faClone, faTrash } from '@fortawesome/free-solid-svg-icons';

export default function ProjStart()
{
  const { user, setUser } = useContext(UserContext);
  const { projects, setProjects, activeProject, setActiveProject} = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);

  function callFunction(func)
  {
    const projectsContext = { projects, setProjects, activeProject, setActiveProject}
    const userContext = { user, setUser };
    const reducerContext = { state, dispatch };

    if (func === 'delete')
      deleteProject(projectsContext, userContext, reducerContext)

      if (func === 'archive')
      archiveProject(projectsContext, userContext, reducerContext)

    else if (func === 'clone')
      cloneProject(projectsContext, userContext, reducerContext)
  }

  return (
    <div className="start">
      
      <div className="start__option start__option--danger" onClick={ () => {callFunction('delete')} }>
        <FontAwesomeIcon icon={ faTrash }/>
        <span>DELETE PROJECT</span>
      </div>
      <div className="start__option start__option--danger" onClick={ () => {callFunction('archive')} }>
        <FontAwesomeIcon icon={ faArchive }/>
        <span>ARCHIVE PROJECT</span>
      </div>
    </div>
  )
}