import React, { useContext, useRef } from 'react';
import { ReducerContext } from 'app';

import TaskTags from './tags/tags';
import TaskDueDate from './badges/due-date';
import { isMyProjectTask, getHoursMinsStr } from 'components/utils/utils';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Task({ itemData }) {

  const { state, dispatch } = useContext(ReducerContext);

  const taskRef = useRef();

  let taskclass = itemData.completed_at ? itemData.confirmed_at ? "completed confirmed" : itemData.completed_at ? "completed " : "notcomplete" : itemData.completed_at ? "completed" : "notcomplete";

  taskclass += (!isMyProjectTask(itemData)) ? " alpha " : "";
  taskclass += (itemData.invoiceable) ? " invoiceable " : "";
  taskclass += (itemData.invoice_id) ? " invoiced " : "";
  taskclass += (itemData.repeats) ? " repeats " : "";


  let hasopentimers = itemData.timesheet?.filter(timesheet => timesheet.hasOwnProperty("start_time") && !timesheet.hasOwnProperty("stop_time")).length > 0 ? true : false;

  return (
    <div className={"task " + taskclass} ref={taskRef} >

      <TaskTags task={itemData} />

      <div className='task__text' onClick={e => { dispatch({ type: "setActiveProjectTaskID", payload: itemData._id }) }} >
        {itemData?.name}<br />
        {hasopentimers && <><FontAwesomeIcon icon={faClock} />&nbsp;&nbsp;</>}
        {getHoursMinsStr(itemData).str}
      </div>
      {
        itemData.due_date && // refactor to account for other badges
        <div className="task__badges">
          <TaskDueDate task={itemData} />
        </div>
      }

    </div>
  )
}