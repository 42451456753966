import React, { useState, useContext, useRef } from 'react';
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';
import Menu from '../components/home/menu/menu';
import Dashboard from '../components/home/dashboard/dashboard';

export default function SettingsPage() {

  const { dispatch, state } = useContext(ReducerContext);
  function getXeroToken() {
    axios.post('/a/xero-auth/', {})
      .then(r => {

        let consentUrl = r.data.consentUrl;
        window.open(consentUrl, '_blank').focus();
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
  }

  function getXeroContacts() {
    axios.post('/a/xero/contacts', {})
      .then(r => {

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
  }

  function getXeroTaxRates() {
    axios.post('/a/xero/taxrates', {})
      .then(r => {

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
  }

  function getXeroItemCodes() {
    axios.post('/a/xero/itemcodes', {})
      .then(r => {

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
  }

  function getXeroInvoices() {
    axios.post('/a/xero/invoices', {})
      .then(r => {

      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
  }


  return (
    <>
      <Menu />
      <div className={`mainpage ${state.menuShown && ' --moved'}`}>
        <a className="btn btn-primary" onClick={e => getXeroToken()}>Get Xero Token</a> <br />
        <a className="btn btn-primary" onClick={e => getXeroItemCodes()}>Import Xero Item Codes</a><br />
        <a className="btn btn-primary" onClick={e => getXeroInvoices()}>Import Xero Invoices</a><br />
        <a className="btn btn-primary" onClick={e => getXeroTaxRates()}>Import Xero Tax Rates</a><br />
      </div>
    </>
  )
}