import React, { useState, useContext, useRef } from 'react';
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from '../../../../utils/axiosConfig';
import Quill from '../../../../components/utils/quill.js';
import { currencyFormat, dateFormat, getHoursMinsStr, convertminstohoursmins, addDays, getTax } from "../../../utils/utils.jsx"

export default function Invoices(props) {
  const { state, dispatch } = useContext(ReducerContext);
  const { activeProject } = useContext(ProjectsContext);
  let blankInvoice = { task_ids: [], net_amount: 0, total_mins: 0, description: "", due_date: dateFormat(addDays(new Date(), 30), "yyyy-MM-dd"), raised_date: dateFormat(new Date(), "yyyy-MM-dd"), invoice_lines: [], new_invoice_line: { task_ids: [], description: "", line_items: [] } }

  const [newActiveProject, setNewActiveProject] = React.useState({ ...activeProject, new_invoice: blankInvoice });

  var project = props.project;


  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave(e);
  }

  function handleSave(e) {
    let thisname = e.target.name;
  }

  function toggleInvoiceCard(e) {

    let new_invoice = newActiveProject.new_invoice;

    let new_invoice_line = new_invoice.new_invoice_line || { line_items: [], mins: 0, hours: 0, description: "" };

    let cardid = "";
    let taskid = "";
    let invoicetask = {}
    let selval = e.target.value;

    if (selval.indexOf("card_") >= 0) {
      cardid = selval.replace("card_", "");
    }

    if (selval.indexOf("task_") >= 0) {
      taskid = selval.replace("task_", "");
    }
    // loop the project cards looking for the selected card
    newActiveProject.cards.map(card => {
      if (cardid == card._id || cardid == "") {
        // matched card loop tasks looking for select task, or tasks in selected card
        card.tasks.map(task => {
          if (task.invoiceable && ((task._id == taskid && cardid == "") || (taskid == "" && cardid == card._id))) {
            // matched task
            if (!task.invoice_id && task.confirmed_at) {

              let tasktime = getHoursMinsStr(task, true) // get time for the matched task

              invoicetask = { ...task, h: tasktime.h, m: tasktime.m };
              invoicetask.cardname = card.name;
              var addtask = true;

              new_invoice_line.line_items?.forEach(invoiceline => { // loop already added new invoice lines
                if (invoiceline.cardname == card.name) {
                  invoiceline.tasks?.forEach(invoicetask => {
                    if (invoicetask._id == task._id) {
                      addtask = false; // already added
                    }
                  })

                  if (addtask) {
                    invoiceline.tasks.push(invoicetask)
                    addtask = false;
                  }
                }
              })

              if (addtask) {
                //we're addin this task to the invoice lines in the new invoice
                new_invoice_line.line_items.push({ cardname: card.name, tasks: [invoicetask] })
              }
            }

          }
        })
      }
    })

    new_invoice_line.description = "";
    let cardmins = 0;
    new_invoice_line.line_items.forEach(card => {

      new_invoice_line.description += `<b>Task Group: ${card.cardname}</b><hr />`

      card.tasks.forEach(task => { // loop the tasks in this card
        var tasktimesheet = "";
        if (task.invoiceable) {
          let tasktime = getHoursMinsStr(task, true); // get total time for this task
          task.totalmins = tasktime.totalmins;
          cardmins += task.totalmins;

          task.timesheet?.forEach(timesheet => { //get the timesheet to add to the description
            tasktimesheet += `${dateFormat(timesheet.date, "nice")} - (${timesheet.hours}h ${timesheet.mins}mins) - ${timesheet.description}<br />`;
          });
          new_invoice_line.description += `<b>Task : ${task.name}</b><br /><b>Timesheet - ${tasktime.h}h ${tasktime.m}m</b><br />` + tasktimesheet + "<hr />";
        }
      })
    });
    console.log("activeProject: ", activeProject);
    if (activeProject.hourly_rate) {
      new_invoice_line.net_amount = (activeProject.hourly_rate / 60) * cardmins;
    }

    setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, new_invoice_line } });
  }

  function deleteLineItem(index) {
    let new_invoice = newActiveProject.new_invoice;

    new_invoice.invoice_lines.splice(index, 1);

    setNewActiveProject({ ...newActiveProject, new_invoice })

  }

  function addLineItem(e) {

    let new_invoice = newActiveProject.new_invoice;

    let new_invoice_line = new_invoice.new_invoice_line;
    let task_ids = [];

    new_invoice_line.line_items.map(line_item => {
      line_item.tasks.map(task => {
        task_ids.push(task._id)
      })
    })

    //let totalmins = new_invoice_line.line_items.map(line_item => { line_item.tasks.map(task => totalmins + getHoursMinsStr(task, true)) });

    const totalmins = new_invoice_line.line_items.reduce((accumulator, line_item) => {
      const taskmins = line_item.tasks.reduce((productAcc, task) => {
        return productAcc + getHoursMinsStr(task, true).totalmins;
      }, 0);
      return accumulator + taskmins;
    }, 0);


    // totalmins = new_invoice_line.line_items.reduce((totalmins, line_item) => (
    //   line_item.tasks.reduce((totalmins, task) => (totalmins += parseFloat(task.totalmins)))
    // ), 0)


    let tax_rate = state.item_codes.find(code => (code.code == new_invoice_line.item_code));
    tax_rate = tax_rate.tax_rate;

    let tax_amount = getTax(new_invoice_line.net_amount, tax_rate, "add")
    let total_amount = new_invoice_line.net_amount + tax_amount
    let new_line_item = {}
    new_line_item.description = new_invoice_line.description;
    new_line_item.quantity = 1;
    new_line_item.net_amount = new_invoice_line.net_amount;
    new_line_item.tax_amount = tax_amount;
    new_line_item.total_amount = total_amount;
    new_line_item.item_code = new_invoice_line.item_code;
    new_line_item.line_items = new_invoice_line.line_items
    new_line_item.tax_rate = tax_rate;
    new_line_item.task_ids = task_ids;
    new_line_item.line_items = null;
    new_line_item.total_mins = totalmins

    new_invoice.net_amount += new_invoice_line.net_amount
    new_invoice.total_mins += totalmins
    new_invoice.invoice_lines.push(new_line_item);

    new_invoice.new_invoice_line.description = null;
    new_invoice.new_invoice_line.net_amount = null;
    new_invoice.new_invoice_line.item_code = null;
    new_invoice.new_invoice_line.hours = null;
    new_invoice.new_invoice_line.mins = null;
    new_invoice.new_invoice_line.line_items = [];

    setNewActiveProject({ ...newActiveProject, new_invoice })
  }

  function submitNewInvoice(e) {
    let new_invoice = newActiveProject.new_invoice;

    let net_amount = 0;
    let tax_amount = 0;
    let total_amount = 0;

    new_invoice.invoice_lines.map(line_item => {
      net_amount += line_item.net_amount;
      tax_amount += line_item.tax_amount;
      total_amount += line_item.total_amount
    })

    new_invoice = {
      hours: new_invoice.hours,
      mins: new_invoice.mins,
      projectid: newActiveProject._id,
      repeat_period: new_invoice.repeat_period,
      client: newActiveProject.client,
      invoice_lines: new_invoice.invoice_lines,
      net_amount: net_amount,
      tax_amount: tax_amount,
      total_amount: total_amount,
      raised_date: new_invoice.raised_date || new Date,
      due_date: new_invoice.due_date,
      created_at: "Just Now"
    };


    let projectinvoices = newActiveProject.invoices || []
    projectinvoices.push(new_invoice);
    let index = projectinvoices.length - 1
    newActiveProject.invoices = projectinvoices;
    

    let errors = [];
    if (!new_invoice.client) {
      errors.push({ description: "Please choose a client" })
    }
    if (new_invoice.invoice_lines.length <= 0) {
      errors.push({ description: "Please add at least one invoice line item" })
    }
    if (errors.length <= 0) {
      axios.post('/a/invoices/create',
        {
          new_invoice
        })
        .then(r => {
          let invoiceid = r.data._id
          newActiveProject.invoices[index]._id = invoiceid;
          console.log("new_invoice: ", new_invoice);

          const cards = newActiveProject.cards.map(c => {
         
              c.tasks.map(t => {

                new_invoice.invoice_lines?.map(line => {
                  line.task_ids?.map(task_id => {
                    if(task_id == t._id){
                      t.invoice_id = invoiceid;
                    }
                  })
                })

               
              })
            
            return c;
          })
      
          dispatch({ type: "updateProject", payload: { ...newActiveProject, cards } })

          setNewActiveProject({ ...newActiveProject, new_invoice: blankInvoice, addinvoice: false })
        })
        .catch(err => {
          setResponseError(err, dispatch);
          setNewActiveProject(activeProject)
        })
    }
    else {
      //do something with the errors
      setNewActiveProject(activeProject)
    }
  }

  // if (!state.itemCodes && !state.fetchItemCodes && newActiveProject.addinvoice) {

  //   dispatch({ action: "getItemCodes" })
  // }




  console.log("new_invoice: ", newActiveProject.new_invoice);
  return (
    <>

      <h3>Invoices</h3><br />
      {
        activeProject.invoices?.length <= 0 && <div className='row'><div className='col-sm-12'>No Invoices Raised Yet</div></div>
      }
      {
        activeProject.invoices?.length > 0 &&
        <div className='row'>
          <div className='col-sm-5'><b>Items</b></div>
          <div className='col-sm-1'><b>Net</b></div>
          <div className='col-sm-1'><b>Tax</b></div>
          <div className='col-sm-1'><b>Total</b></div>
          <div className='col-sm-1'><b>Raised Date</b></div>
          <div className='col-sm-1'><b>Due Date</b></div>
          <div className='col-sm-1'><b>Paid Date</b></div>
          <div className='col-sm-1'><b>Timesheet</b></div>
        </div>
      }
      {activeProject.invoices?.map((invoice, index) => {

        return (
          <div className='row' key={index}>
            <div className='col-sm-5'>
              {
                invoice.invoice_lines?.map((line, i) => {
                  return (
                    <div className="hiddenoverflow" style={{ "height": "46px" }} dangerouslySetInnerHTML={{ __html: line.description }}></div>
                  )
                })
              }
            </div>
            <div className='col-sm-1'>{currencyFormat(invoice.net_amount)}</div>
            <div className='col-sm-1'>{currencyFormat(invoice.tax_amount)}</div>
            <div className='col-sm-1'>{currencyFormat(invoice.total_amount)}</div>
            <div className='col-sm-1'>{dateFormat(invoice.raised_date)}</div>
            <div className='col-sm-1'>{dateFormat(invoice.due_date)}</div>
            <div className='col-sm-1'></div>
            <div className='col-sm-1'>{`${convertminstohoursmins(invoice.total_mins).str}`}</div>
          </div>
        )
      })}

      {
        !newActiveProject.addinvoice && <><br /><a className="btn btn-primary" onClick={e => { setNewActiveProject({ ...newActiveProject, addinvoice: true }) }}>Raise New Invoice</a></>
      }

      {newActiveProject.addinvoice &&
        <>
          <hr />
          <div className='row'>
            <div className='col-sm-12'><b>New Invoice</b></div>
          </div>
          {
            newActiveProject.new_invoice.invoice_lines?.length > 0 && <>
              <div className='row'>
                <div className='col-sm-1'><b>Qty</b></div>
                <div className='col-sm-6'><b>Description</b></div>
                <div className='col-sm-1'><b>Item Code</b></div>
                <div className='col-sm-1'><b>Net</b></div>
                <div className='col-sm-1'><b>Tax</b></div>
                <div className='col-sm-1'><b>Total</b></div>
                <div className='col-sm-1'></div>
              </div>

              {
                newActiveProject.new_invoice.invoice_lines?.map((lineItem, index) => {
                  return (
                    <div className='row'>
                      <div className='col-sm-1'>{lineItem.quantity}</div>
                      <div className='col-sm-6'><div className="hiddenoverflow" dangerouslySetInnerHTML={{ __html: lineItem.description }} /></div>
                      <div className='col-sm-1'>{lineItem.item_code}</div>
                      <div className='col-sm-1'>{currencyFormat(lineItem.net_amount)}</div>
                      <div className='col-sm-1'>{currencyFormat(lineItem.tax_amount)}</div>
                      <div className='col-sm-1'>{currencyFormat(lineItem.total_amount)}</div>
                      <div className='col-sm-1'><a onClick={e => { deleteLineItem(index) }}>Delete Item</a></div>
                    </div>
                  )
                })
              }
            </>
          }

          <hr />
          <div className='row'>
            <div className='col-sm-12'><h6>New Invoice Line Item

              <select name="new_invoice_line" className="form-control" onChange={e => { toggleInvoiceCard(e) }}>
                <option>Choose A Card or Task</option>
                {
                  newActiveProject.cards?.map((card, index) => {

                    let invoiecabletasks = card.tasks.filter(task => task.invoiceable).length;

                    if (invoiecabletasks <= 0 ) return 
                    return (
                      <>
                        <option value={"card_" + card._id}>{card.name}</option>
                        {
                          card.tasks.map((task, index) => {
                            if (task.invoiceable && !task.invoice_id) {
                              let desc = !task.completed_at ? " - not yet complete" : (!task.confirmed_at) ? " - not yet confirmed " : "";
                              return (
                                <option disabled={task.invoice_id || !task.confirmed_at} value={"task_" + task._id}> -- {task.name + " " + getHoursMinsStr({ cards: [{ tasks: [task] }] }).str} {desc}</option>
                              )
                            }
                          })
                        }
                      </>
                    )
                  })
                }
              </select>
            </h6></div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-sm-5'>
              {
                newActiveProject.editnew_invoicedesc && <>
                  <Quill value={newActiveProject.new_invoice.new_invoice_line.description ? newActiveProject.new_invoice.new_invoice_line.description : ""} onChange={(e) => { setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, new_invoice_line: { ...newActiveProject.new_invoice.new_invoice_line, description: e } } }) }} />
                  <a onClick={e => { setNewActiveProject({ ...newActiveProject, editnew_invoicedesc: false }) }} className='btn btn-primary btn-small'>save description</a>
                </>
              }
              {
                !newActiveProject.editnew_invoicedesc && <>
                  <div className="hiddenoverflow" onClick={e => { setNewActiveProject({ ...newActiveProject, editnew_invoicedesc: true }) }} dangerouslySetInnerHTML={{ __html: newActiveProject.new_invoice.new_invoice_line.description }} />
                  {
                    !newActiveProject.new_invoice.new_invoice_line.description && <>
                      <br /> <a onClick={e => { setNewActiveProject({ ...newActiveProject, editnew_invoicedesc: true }) }} className='btn btn-primary btn-small'>Add Description</a>
                    </>
                  }
                </>
              }
            </div>
            <div className='col-sm-2'>
              <select name="new_invoice_item_code" className="form-control" onChange={e => { setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, new_invoice_line: { ...newActiveProject.new_invoice.new_invoice_line, item_code: e.target.value } } }) }}>
                <option value=''>Choose A Code</option>
                {
                  state.item_codes?.map((code, index) => {
                    return (
                      <option key={index} value={code.code}>{code.name}</option>
                    );
                  })
                }
              </select>
            </div>
            <div className='col-sm-2'>
              <input
                id="new_invoice_netamount"
                name="new_invoice_netamount"
                type="text"
                placeholder="Amount"
                className="form-control"
                value={newActiveProject.new_invoice.new_invoice_line.net_amount}
                style={{ width: '100%' }}
                onChange={e => { setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, new_invoice_line: { ...newActiveProject.new_invoice.new_invoice_line, net_amount: parseFloat(e.target.value) } } }) }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className='col-sm-2'>
              {
                newActiveProject.new_invoice.new_invoice_line.description && newActiveProject.new_invoice.new_invoice_line.net_amount && newActiveProject.new_invoice.new_invoice_line.item_code &&
                <button type="submit" onClick={e => addLineItem()} className="btn btn-primary text-right" name="save_newresource" value="Add" >Add Line Item</button>
              }
            </div>
          </div>
          <hr />

          {

            newActiveProject.new_invoice.invoice_lines?.length > 0 &&
            <div className='row'>
              <div className='col-sm-4 invoicebox'>
                <div className='row'>
                  <div className='col-sm-6'><b>Total Invoice Time</b></div>
                  <div className='col-sm-6'>{convertminstohoursmins(newActiveProject.new_invoice.total_mins).str}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'><b>Net Invoice Amount</b></div>
                  <div className='col-sm-6'>{currencyFormat(
                    newActiveProject.new_invoice?.invoice_lines?.reduce((total, item) => (
                      !isNaN(item.total_amount) ? (total += (Number(item.total_amount)))
                        : total
                    ), 0)
                  )}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'><b>Raised Date</b></div>
                  <div className='col-sm-6'><input

                    id="new_invoice_raised_date"
                    name="new_invoice_raised_date"
                    type="date"
                    placeholder="Raised Date"
                    className="form-control"
                    value={newActiveProject.new_invoice.raised_date}
                    style={{ width: '100%' }}
                    onChange={e => { setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, raised_date: e.target.value } }) }}
                    onKeyDown={handleKeyDown}
                  /></div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'><b>Due Date</b></div>
                  <div className='col-sm-6'><input

                    id="new_invoice_due_date"
                    name="new_invoice_due_date"
                    type="date"
                    placeholder="Due Date"
                    className="form-control"
                    value={newActiveProject.new_invoice.due_date}
                    style={{ width: '100%' }}
                    onChange={e => { setNewActiveProject({ ...newActiveProject, new_invoice: { ...newActiveProject.new_invoice, due_date: e.target.value } }) }}
                    onKeyDown={handleKeyDown}
                  /></div>
                </div>



                <div className='row'>
                  <div className='col-sm-6'><a className="btn btn-danger text-left" onClick={e => setNewActiveProject({ ...newActiveProject, addinvoice: false })}>Cancel</a></div>
                  <div className='col-sm-6'><button type="submit" onClick={e => submitNewInvoice()} className="btn btn-primary text-right" name="raise_invoice" value="Add" >Raise Invoice</button></div>
                </div>


              </div>
            </div>
          }


        </>
      }
    </>
  )
}
